import * as React from "react"
import { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import { Helmet } from "react-helmet"

import {
  Header,
  ShoppingList,
  Footer,
  BottomNavbar,
  NoAuth,
  NoAccess,
  Loader,
} from "../components/index"

import mockup from "../utils/data"
import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

// Context
import UserContext from "../context/UserAuth"

// utils
import { getCurrentWeek } from "../utils/dateHelper"

import FadeIn from "../components/Animation/FadeIn"

const IndexPage = ({ data }) => {
  const { user, isAuthenticated, isAccess, isLoading, startTime } = useContext(
    UserContext
  ) || {
    user: null,
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
    startTime: "2022-08-15",
  }

  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [currWeek] = useState(getCurrentWeek(startTime))

  useEffect(() => {
    if (user) {
      setLoading(true)
      getMealPlan(user.sub)
    }
  }, [user])

  const getMealPlan = _id => {
    axios({
      method: "get",
      url: `https://esteekeyapi.com/getdata?id=${_id}`,
    })
      .then(res => {
        setUserData(res.data.data[0])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <div>
      <Helmet>
        <title>Esteekey - Shopping List</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        {isLoading && <Loader />}
        {!isAuthenticated ? (
          <NoAuth />
        ) : !isAccess ? (
          <NoAccess />
        ) : (
          <div>
            <div className="mt-6 xl:mt-16 mb-12  mx-4 sm:mx-0 ">
              <Container>
                <FadeIn>
                  <SubTitle
                    title1="Список"
                    title2="покупок"
                    text="For this week"
                  />
                </FadeIn>
              </Container>
            </div>
            {userData && (
              <ShoppingList
                key="weekly"
                list={userData?.mealPlan}
                recipeList={data?.meals?.nodes}
              />
            )}
            <div className="my-20">
              <div className="mb-6  mx-4 sm:mx-0 ">
                <Container>
                  <FadeIn>
                    <SubTitle
                      title1="Daily"
                      title2="Shopping List"
                      text="For Today"
                    />
                  </FadeIn>
                </Container>
              </div>
              {userData && (
                <ShoppingList
                  key="daily"
                  list={userData?.mealPlan}
                  recipeList={data?.meals?.nodes}
                  bgWhite
                  isToday
                  currWeek={currWeek}
                  isDialy
                />
              )}
            </div>
          </div>
        )}
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query ShoppingList1 {
    meals: allSanityMeal {
      nodes {
        _id
        nameRU
        name
        image {
          asset {
            url
          }
        }
        calories
        carbs
        fat
        protein
        mealTime {
          name
          nameRU
        }
        slug {
          current
        }
        recipe {
          name
          nameRU
          ingredients {
            name {
              name
              nameRU
            }
            unit {
              name
              nameRU
            }
            amount
          }
        }
      }
    }
  }
`

export default IndexPage
